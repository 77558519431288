import { createRouter, createWebHistory } from 'vue-router'
import {logout} from "@/rest/api/auth";
import {destroySession, isSessionExisting, setSession} from "@/rest/jwt";
import {useGeneralStore} from "@/stores/general";
import {getMyUser} from "@/rest/api/users";
import {activateUser} from "@/rest/api/users.activation";
import type {LoginToken} from "@/rest/dto/auth";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'website',
      component: () => import('@/views/WebsiteView.vue'),
      children: [
        {
          path: '',
          name: 'website-home',
          component: () => import('@/views/website/HomePage.vue')
        },
        {
          path: 'roadmap',
          name: 'website-roadmap',
          component: () => import('@/views/website/RoadmapPage.vue')
        },
        {
          path: 'legal',
          name: 'website-legal',
          component: () => import('@/views/website/LegalPage.vue')
        },
        {
          path: 'blog',
          name: 'website-blog',
          component: () => import('@/views/website/BlogsPage.vue')
        },
        {
          path: 'blog/:slug',
          name: 'website-blog-post',
          props: true,
          component: () => import('@/views/website/BlogPostPage.vue')
        }
      ]
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/RegisterView.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/LoginView.vue'),
    },
    {
      path: '/login/finish-discord',
      name: 'login-finish-discord',
      component: () => null,
      beforeEnter: (to, _, next) => {
        if (to.query.success && to.query.success === "true" && to.query.tokend) {
          const base64 = to.query.tokend as string;
          const tokenJson = atob(base64);
          const pair = JSON.parse(tokenJson) as LoginToken;
          setSession(pair.token, pair.expiresIn);

          if (to.query.redirect) {
            next(atob(to.query.redirect as string));
          } else {
            next({ name: 'panel-dashboard' });
          }
        } else {
          next({ name: 'login' });
        }
      }
    },
    {
      path: '/login/ingame',
      name: 'login-ingame',
      component: () => import('@/views/LoginIngameView.vue')
    },
    {
      path: '/logout',
      name: 'logout',
      meta: {
        ignoreBefore: true
      },
      component: () => null,
      beforeEnter: async (_, __, next) => {
        await _logout();

        next({ name: 'login' });
      }
    },
    {
      path: '/activate/:userID',
      name: 'activate',
      meta: {
        ignoreBefore: true
      },
      component: () => null,
      beforeEnter: async (to, _, next) => {
        const userID = to.params.userID as string;
        const token = to.query.act as string;
        if (userID && token && userID.trim().length > 0 && token.trim().length > 0) {
          const success = await activateUser(userID, token);
          if (to.query.stop) {
            next();
            return;
          }

          if (success) {
            next({ name: 'login' });
          } else {
            next({ name: 'home' });
          }
        } else {
          next({ name: 'home' });
        }
      }
    },
    {
      path: '/reset-password/:userID',
      name: 'reset-password',
      meta: {
        ignoreBefore: true
      },
      component: () => import('@/views/ResetPasswordView.vue'),
      beforeEnter: async (to, _, next) => {
        const userID = to.params.userID as string;
        const token = to.query.ret as string;
        const totp = to.query.totp as string;
        if (userID && token && totp && userID.trim().length > 0 && token.trim().length > 0 && (totp === "true" || totp === "false")) {
          next();
        } else {
          next({ name: 'home' });
        }
      }
    },
    {
      path: '/oauth2/:appId/authenticate',
      name: 'oauth2-authenticate',
      props: true,
      component: () => import('@/views/OAuth2AuthenticateView.vue'),
    },
    {
      path: '/panel',
      name: 'panel',
      component: () => import('@/views/ControlPanelView.vue'),
      children: [
        {
          path: 'dashboard',
          name: 'panel-dashboard',
          component: () => import('@/views/panel/DashboardView.vue')
        },
        {
          path: 'whitelist/:characterId/:whitelistId',
          name: 'panel-whitelist',
          props: true,
          component: () => import('@/views/panel/WhitelistView.vue')
        },
        {
          path: 'settings',
          name: 'panel-settings',
          component: () => import('@/views/panel/SettingsView.vue')
        },
        {
          path: 'wallpaper-requests',
          name: 'panel-wallpaper-requests',
          component: () => import('@/views/panel/WallpaperRequestsView.vue')
        },
        {
          path: 'admin',
          name: 'panel-admin',
          component: () => import('@/views/panel/AdminView.vue'),
          children: [
            {
              path: 'users',
              name: 'panel-admin-users',
              component: () => import('@/views/panel/admin/users/Users.vue')
            },
            {
              path: 'users/:id',
              name: 'panel-admin-users-edit',
              props: true,
              component: () => import('@/views/panel/admin/users/UserEdit.vue')
            },
            {
              path: 'characters',
              name: 'panel-admin-characters',
              component: () => import('@/views/panel/admin/users/Characters.vue')
            },
            {
              path: 'characters/:id',
              name: 'panel-admin-characters-edit',
              props: true,
              component: () => import('@/views/panel/admin/users/CharacterEdit.vue')
            },
            {
              path: 'developers/oauth2-apps',
              name: 'panel-admin-developers-oauth2-apps',
              component: () => import('@/views/panel/admin/developers/OAuth2AppsView.vue')
            },
            {
              path: 'developers/oauth2-apps/:id',
              name: 'panel-admin-developers-oauth2-apps-edit',
              props: true,
              component: () => import('@/views/panel/admin/developers/OAuth2AppEditView.vue')
            },
            {
              path: 'developers/keybinding-types',
              name: 'panel-admin-developers-keybinding-types',
              component: () => import('@/views/panel/admin/developers/KeybindingTypesView.vue')
            },
            {
              path: 'developers/keybinding-defaults',
              name: 'panel-admin-developers-keybinding-defaults',
              component: () => import('@/views/panel/admin/developers/DefaultKeybindingsView.vue')
            },
            {
              path: 'developers/services-reload',
              name: 'panel-admin-developers-services-reload',
              component: () => import('@/views/panel/admin/developers/ServicesReload.vue')
            },
            {
              path: 'developers/cell-broadcast',
              name: 'panel-admin-developers-cell-broadcast',
              component: () => import('@/views/panel/admin/developers/CellBroadcastView.vue')
            },
            {
              path: 'commands',
              name: 'panel-admin-commands',
              component: () => import('@/views/panel/admin/CommandsView.vue')
            },
            {
              path: 'permission-groups',
              name: 'panel-admin-permission-groups',
              component: () => import('@/views/panel/admin/permissions/PermissionGroupsView.vue')
            },
            {
              path: 'permission-groups/:id',
              name: 'panel-admin-permission-groups-edit',
              props: true,
              component: () => import('@/views/panel/admin/permissions/PermissionGroupEditView.vue')
            },
            {
              path: 'animation-categories',
              name: 'panel-admin-animations-categories',
              component: () => import('@/views/panel/admin/animations/AnimationCategories.vue')
            },
            {
              path: 'animationn-categories/:id',
              name: 'panel-admin-animations-categories-edit',
              props: true,
              component: () => import('@/views/panel/admin/animations/AnimationCategoryEdit.vue')
            },
            {
              path: 'animation-props',
              name: 'panel-admin-animations-props',
              component: () => import('@/views/panel/admin/animations/AnimationProps.vue')
            },
            {
              path: 'animation-props/:id',
              name: 'panel-admin-animations-props-edit',
              props: true,
              component: () => import('@/views/panel/admin/animations/AnimationPropEdit.vue')
            },
            {
              path: 'animations',
              name: 'panel-admin-animations-anim',
              component: () => import('@/views/panel/admin/animations/Animations.vue')
            },
            {
              path: 'animations/:id',
              name: 'panel-admin-animations-anim-edit',
              props: true,
              component: () => import('@/views/panel/admin/animations/AnimationEdit.vue')
            },
            {
              path: 'animations/scenarios',
              name: 'panel-admin-animations-scenarios',
              component: () => import('@/views/panel/admin/animations/Scenarios.vue')
            },
            {
              path: 'animations/scenarios/:id',
              name: 'panel-admin-animations-scenarios-edit',
              props: true,
              component: () => import('@/views/panel/admin/animations/ScenariosEdit.vue')
            },
            {
              path: 'vehicles/tuning-parts',
              name: 'panel-admin-tuning-parts',
              component: () => import('@/views/panel/admin/vehicles/TuningParts.vue')
            },
            {
              path: 'vehicles/tuning-parts/:id',
              name: 'panel-admin-tuning-parts-edit',
              props: true,
              component: () => import('@/views/panel/admin/vehicles/TuningPartEdit.vue')
            },
            {
              path: 'vehicles/brands',
              name: 'panel-admin-vehicle-brands',
              component: () => import('@/views/panel/admin/vehicles/VehicleBrands.vue')
            },
            {
              path: 'vehicles/brands/:id',
              name: 'panel-admin-vehicle-brands-edit',
              props: true,
              component: () => import('@/views/panel/admin/vehicles/VehicleBrandEdit.vue')
            },
            {
              path: 'vehicles/types',
              name: 'panel-admin-vehicle-types',
              component: () => import('@/views/panel/admin/vehicles/VehicleTypes.vue')
            },
            {
              path: 'vehicles/types/:id',
              name: 'panel-admin-vehicle-types-edit',
              props: true,
              component: () => import('@/views/panel/admin/vehicles/VehicleTypeEdit.vue')
            },
            {
              path: 'website/legal',
              name: 'panel-admin-website-legal',
              component: () => import('@/views/panel/admin/website/LegalView.vue')
            },
            {
              path: 'website/settings',
              name: 'panel-admin-website-settings',
              component: () => import('@/views/panel/admin/website/SettingsView.vue')
            },
            {
              path: 'website/roadmap',
              name: 'panel-admin-website-roadmap',
              component: () => import('@/views/panel/admin/website/RoadmapView.vue')
            },
            {
              path: 'website/blog-posts',
              name: 'panel-admin-website-blog-posts',
              component: () => import('@/views/panel/admin/website/BlogPostsView.vue')
            },
            {
              path: 'website/blog-posts/:id',
              name: 'panel-admin-website-blog-posts-edit',
              props: true,
              component: () => import('@/views/panel/admin/website/BlogPostEditView.vue')
            },
            {
              path: 'items/alcohol-calc',
              name: 'panel-admin-item-alcohol-calc',
              component: () => import('@/views/panel/admin/items/AlcoholCalculator.vue')
            },
            {
              path: 'items/definitions',
              name: 'panel-admin-item-definitions',
              component: () => import('@/views/panel/admin/items/ItemDefinitions.vue')
            },
            {
              path: 'items/definitions/:id',
              name: 'panel-admin-item-definitions-edit',
              props: true,
              component: () => import('@/views/panel/admin/items/ItemDefinitionEdit.vue')
            },
            {
              path: 'items/pool-groups',
              name: 'panel-admin-items-pool-groups',
              component: () => import('@/views/panel/admin/items/ItemPoolGroups.vue')
            },
            {
              path: 'items/pool-groups/:id',
              name: 'panel-admin-items-pool-groups-edit',
              props: true,
              component: () => import('@/views/panel/admin/items/ItemPoolGroupEdit.vue')
            },
            {
              path: 'items/pools',
              name: 'panel-admin-items-pools',
              component: () => import('@/views/panel/admin/items/ItemPools.vue')
            },
            {
              path: 'items/pools/:id',
              name: 'panel-admin-items-pools-edit',
              props: true,
              component: () => import('@/views/panel/admin/items/ItemPoolEdit.vue')
            },
            {
              path: 'crafting/recipes',
              name: 'panel-admin-crafting-recipes',
              component: () => import('@/views/panel/admin/items/CraftingRecipes.vue')
            },
            {
              path: 'crafting/recipes/:id',
              name: 'panel-admin-crafting-recipes-edit',
              props: true,
              component: () => import('@/views/panel/admin/items/CraftingRecipeEdit.vue')
            },
            {
              path: 'world/sitableobjects',
              name: 'panel-admin-item-sitableobjects',
              component: () => import('@/views/panel/admin/world/SitableObjects.vue')
            },
            {
              path: 'world/sitableobjects/:id',
              name: 'panel-admin-item-sitableobjects-edit',
              props: true,
              component: () => import('@/views/panel/admin/world/SitableObjectsEdit.vue')
            },
            {
              path: 'world/gas_pumps',
              name: 'panel-admin-world-gas_pumps',
              component: () => import('@/views/panel/admin/world/GasPumps.vue')
            },
            {
              path: 'world/gas_pumps/:id',
              name: 'panel-admin-world-gas_pumps-edit',
              props: true,
              component: () => import('@/views/panel/admin/world/GasPumpEdit.vue')
            },
            {
              path: 'world/garages',
              name: 'panel-admin-world-garages',
              component: () => import('@/views/panel/admin/world/Garages.vue')
            },
            {
              path: 'world/garages/:id',
              name: 'panel-admin-world-garages-edit',
              props: true,
              component: () => import('@/views/panel/admin/world/GarageEdit.vue')
            },
            {
              path: 'world/collection-points',
              name: 'panel-admin-world-collection-points',
              component: () => import('@/views/panel/admin/world/CollectionPoints.vue')
            },
            {
              path: 'world/collection-points/:id',
              name: 'panel-admin-world-collection-points-edit',
              props: true,
              component: () => import('@/views/panel/admin/world/CollectionPointEdit.vue')
            },
            {
              path: 'world/interaction-webviews',
              name: 'panel-admin-world-interaction-webviews',
              component: () => import('@/views/panel/admin/world/InteractionWebviews.vue')
            },
            {
              path: 'world/interaction-webviews/:id',
              name: 'panel-admin-world-interaction-webviews-edit',
              props: true,
              component: () => import('@/views/panel/admin/world/InteractionWebviewEdit.vue')
            },
            {
              path: 'inventories/item_sets',
              name: 'panel-admin-inventories-item-sets',
              component: () => import('@/views/panel/admin/inventories/ItemSets.vue')
            },
            {
              path: 'inventories/item_sets/:id',
              name: 'panel-admin-inventories-item-sets-edit',
              props: true,
              component: () => import('@/views/panel/admin/inventories/ItemSetEdit.vue')
            },
            {
              path: 'inventories/types',
              name: 'panel-admin-inventories-types',
              component: () => import('@/views/panel/admin/inventories/InventoryTypes.vue')
            },
            {
              path: 'inventories/types/:id',
              name: 'panel-admin-inventories-types-edit',
              props: true,
              component: () => import('@/views/panel/admin/inventories/InventoryTypeEdit.vue')
            },
            {
              path: 'clothes',
              name: 'panel-admin-clothes',
              props: true,
              component: () => import('@/views/panel/admin/clothes/Clothes.vue')
            },
            {
              path: 'clothes/:id',
              name: 'panel-admin-clothes-edit',
              props: true,
              component: () => import('@/views/panel/admin/clothes/ClothEdit.vue')
            },
            {
              path: 'clothes/collections',
              name: 'panel-admin-clothes-collections',
              props: true,
              component: () => import('@/views/panel/admin/clothes/ClothCollections.vue')
            },
            {
              path: 'clothes/collections/:id',
              name: 'panel-admin-clothes-collections-edit',
              props: true,
              component: () => import('@/views/panel/admin/clothes/ClothCollectionEdit.vue')
            },
            {
              path: 'world/positions',
              name: 'panel-admin-world-positions',
              component: () => import('@/views/panel/admin/world/SavedPositions.vue')
            },
            {
              path: 'world/doors',
              name: 'panel-admin-world-doors',
              component: () => import('@/views/panel/admin/world/Doors.vue')
            },
            {
              path: 'world/doors/:id',
              name: 'panel-admin-world-doors-edit',
              props: true,
              component: () => import('@/views/panel/admin/world/DoorEdit.vue')
            },
            {
              path: 'world/door-groups',
              name: 'panel-admin-world-door-groups',
              component: () => import('@/views/panel/admin/world/DoorGroups.vue')
            },
            {
              path: 'world/door-groups/:id',
              name: 'panel-admin-world-door-groups-edit',
              props: true,
              component: () => import('@/views/panel/admin/world/DoorGroupEdit.vue')
            },
            {
              path: 'flats/entrances',
              name: 'panel-admin-flats-entrances',
              component: () => import('@/views/panel/admin/flats/FlatEntrances.vue')
            },
            {
              path: 'flats/entrances/:id',
              name: 'panel-admin-flats-entrances-edit',
              props: true,
              component: () => import('@/views/panel/admin/flats/FlatEntranceEdit.vue')
            },
            {
              path: 'flats/interiors',
              name: 'panel-admin-flats-interiors',
              component: () => import('@/views/panel/admin/flats/FlatInteriors.vue')
            },
            {
              path: 'flats/interiors/:id',
              name: 'panel-admin-flats-interiors-edit',
              props: true,
              component: () => import('@/views/panel/admin/flats/FlatInteriorEdit.vue')
            },
            {
              path: 'flats',
              name: 'panel-admin-flats',
              component: () => import('@/views/panel/admin/flats/Flats.vue')
            },
            {
              path: 'flats/:id',
              name: 'panel-admin-flats-edit',
              props: true,
              component: () => import('@/views/panel/admin/flats/FlatEdit.vue')
            },
            {
              path: 'world/inventories',
              name: 'panel-admin-world-inventories',
              component: () => import('@/views/panel/admin/world/WorldInventories.vue')
            },
            {
              path: 'world/inventories/:id',
              name: 'panel-admin-world-inventories-edit',
              props: true,
              component: () => import('@/views/panel/admin/world/WorldInventoryEdit.vue')
            },
            {
              path: 'banking/banks',
              name: 'panel-admin-banking-banks',
              component: () => import('@/views/panel/admin/banking/Banks.vue')
            },
            {
              path: 'banking/banks/:id',
              name: 'panel-admin-banking-banks-edit',
              props: true,
              component: () => import('@/views/panel/admin/banking/BankEdit.vue')
            },
            {
              path: 'banking/templates',
              name: 'panel-admin-banking-templates',
              component: () => import('@/views/panel/admin/banking/BankAccountTemplates.vue')
            },
            {
              path: 'banking/templates/:id',
              name: 'panel-admin-banking-templates-edit',
              props: true,
              component: () => import('@/views/panel/admin/banking/BankAccountTemplateEdit.vue')
            },
            {
              path: 'world/atms',
              name: 'panel-admin-world-atms',
              component: () => import('@/views/panel/admin/world/ATMs.vue')
            },
            {
              path: 'world/atms/:id',
              name: 'panel-admin-world-atms-edit',
              props: true,
              component: () => import('@/views/panel/admin/world/ATMEdit.vue')
            },
            {
              path: 'world/npcs',
              name: 'panel-admin-world-npcs',
              component: () => import('@/views/panel/admin/world/NPCs.vue')
            },
            {
              path: 'world/npcs/:id',
              name: 'panel-admin-world-npcs-edit',
              props: true,
              component: () => import('@/views/panel/admin/world/NPCEdit.vue')
            },
            {
              path: 'world/elevators',
              name: 'panel-admin-world-elevators',
              component: () => import('@/views/panel/admin/world/Elevators.vue')
            },
            {
              path: 'world/elevators/:id',
              name: 'panel-admin-world-elevators-edit',
              props: true,
              component: () => import('@/views/panel/admin/world/ElevatorsEdit.vue')
            },
            {
              path: 'world/blips',
              name: 'panel-admin-world-blips',
              component: () => import('@/views/panel/admin/world/Blips.vue')
            },
            {
              path: 'world/blips/:id',
              name: 'panel-admin-world-blips-edit',
              props: true,
              component: () => import('@/views/panel/admin/world/BlipEdit.vue')
            },
            {
              path: 'world/cloth_shops',
              name: 'panel-admin-world-cloth_shops',
              component: () => import('@/views/panel/admin/world/ClothShops.vue')
            },
            {
              path: 'world/cloth_shops/:id',
              name: 'panel-admin-world-cloth_shops-edit',
              props: true,
              component: () => import('@/views/panel/admin/world/ClothShopEdit.vue')
            },
            {
              path: 'world/weather_days',
              name: 'panel-admin-world-weatherdays',
              component: () => import('@/views/panel/admin/world/WeatherDays.vue')
            },
            {
              path: 'world/weather_days/:id',
              name: 'panel-admin-world-weatherdays-edit',
              props: true,
              component: () => import('@/views/panel/admin/world/WeatherDayEdit.vue')
            },
            {
              path: 'world/shot_callers',
              name: 'panel-admin-world-shot_callers',
              component: () => import('@/views/panel/admin/world/ShotCallers.vue')
            },
            {
              path: 'world/shot_callers/:id',
              name: 'panel-admin-world-shot_callers-edit',
              props: true,
              component: () => import('@/views/panel/admin/world/ShotCallerEdit.vue')
            },
            {
              path: 'world/ars',
              name: 'panel-admin-world-ars',
              component: () => import('@/views/panel/admin/world/AirRaidSirens.vue')
            },
            {
              path: 'world/ars/:id',
              name: 'panel-admin-world-ars-edit',
              props: true,
              component: () => import('@/views/panel/admin/world/AirRaidSirenEdit.vue')
            },
            {
              path: 'world/shoplifts',
              name: 'panel-admin-world-shoplifts',
              component: () => import('@/views/panel/admin/world/Shoplifts.vue')
            },
            {
              path: 'world/shoplifts/:id',
              name: 'panel-admin-world-shoplifts-edit',
              props: true,
              component: () => import('@/views/panel/admin/world/ShopliftEdit.vue')
            },
            {
              path: 'factions',
              name: 'panel-admin-factions',
              component: () => import('@/views/panel/admin/factions/Factions.vue')
            },
            {
              path: 'factions/:id',
              name: 'panel-admin-factions-edit',
              props: true,
              component: () => import('@/views/panel/admin/factions/FactionEdit.vue')
            },
            {
              path: 'factions/mail_suffixes',
              name: 'panel-admin-mail_suffixes',
              component: () => import('@/views/panel/admin/factions/MailSuffixes.vue')
            },
            {
              path: 'factions/mail_suffixes/:id',
              name: 'panel-admin-mail_suffixes-edit',
              props: true,
              component: () => import('@/views/panel/admin/factions/MailSuffixEdit.vue')
            },
            {
              path: 'gamedesign/code_game',
              name: 'panel-admin-gd-casino-code_game',
              component: () => import('@/views/panel/admin/gamedesign/CasinoCodeGame.vue')
            },
            {
              path: 'gamedesign/slot-themes',
              name: 'panel-admin-gd-casino-slot_themes',
              component: () => import('@/views/panel/admin/gamedesign/CasinoSlotThemes.vue')
            },
            {
              path: 'gamedesign/slot-themes/:id',
              name: 'panel-admin-gd-casino-slot_themes-edit',
              props: true,
              component: () => import('@/views/panel/admin/gamedesign/CasinoSlotThemeEdit.vue')
            },
            {
              path: 'gamedesign/geocaching/points',
              name: 'panel-admin-gd-geocaching-points',
              component: () => import('@/views/panel/admin/gamedesign/GeocachingPoints.vue')
            },
            {
              path: 'gamedesign/geocaching/points/:id',
              name: 'panel-admin-gd-geocaching-points-edit',
              props: true,
              component: () => import('@/views/panel/admin/gamedesign/GeocachingPointEdit.vue')
            },
            {
              path: 'gamedesign/geocaching/events',
              name: 'panel-admin-gd-geocaching-events',
              component: () => import('@/views/panel/admin/gamedesign/GeocachingEvents.vue')
            },
            {
              path: 'gamedesign/geocaching/events/:id',
              name: 'panel-admin-gd-geocaching-events-edit',
              props: true,
              component: () => import('@/views/panel/admin/gamedesign/GeocachingEventEdit.vue')
            },
            {
              path: 'gamedesign/tattoos',
              name: 'panel-admin-gd-tattoos',
              component: () => import('@/views/panel/admin/gamedesign/Tattoos.vue')
            },
            {
              path: 'gamedesign/tattoos/:id',
              name: 'panel-admin-gd-tattoos-edit',
              props: true,
              component: () => import('@/views/panel/admin/gamedesign/TattooEdit.vue')
            },
            {
              path: 'gamedesign/drugs',
              name: 'panel-admin-gd-drugs',
              component: () => import('@/views/panel/admin/gamedesign/Drugs.vue')
            },
            {
              path: 'gamedesign/drugs/:id',
              name: 'panel-admin-gd-drugs-edit',
              props: true,
              component: () => import('@/views/panel/admin/gamedesign/DrugEdit.vue')
            },
            {
              path: 'gamedesign/laws',
              name: 'panel-admin-gd-laws',
              component: () => import('@/views/panel/admin/gamedesign/LawCodes.vue')
            },
            {
              path: 'gamedesign/laws/:id',
              name: 'panel-admin-gd-laws-edit',
              props: true,
              component: () => import('@/views/panel/admin/gamedesign/LawCodeEdit.vue')
            },
            {
              path: 'support/wallpaper-requests',
              name: 'panel-admin-support-wallpaper-requests',
              component: () => import('@/views/panel/admin/support/WallpaperRequests.vue')
            },
            {
              path: 'support/player-map',
              name: 'panel-admin-support-player-map',
              component: () => import('@/views/panel/admin/support/PlayerMap.vue')
            },
            {
              path: 'support/whitelist-forms',
              name: 'panel-admin-support-whitelist-forms',
              component: () => import('@/views/panel/admin/support/WhitelistForms.vue')
            },
            {
              path: 'support/whitelist-forms/:id',
              name: 'panel-admin-support-whitelist-forms-edit',
              props: true,
              component: () => import('@/views/panel/admin/support/WhitelistFormEdit.vue')
            },
            {
              path: 'support/whitelists',
              name: 'panel-admin-support-whitelists',
              component: () => import('@/views/panel/admin/support/Whitelists.vue')
            },
            {
              path: 'support/whitelists/:id',
              name: 'panel-admin-support-whitelists-edit',
              props: true,
              component: () => import('@/views/panel/admin/support/WhitelistEdit.vue')
            },
            {
              path: 'support/whitelist-immigrations',
              name: 'panel-admin-support-whitelist-immigrations',
              component: () => import('@/views/panel/admin/support/WhitelistImmigrations.vue')
            },
            {
              path: 'support/notify-broadcast',
              name: 'panel-admin-support-notifications-broadcast',
              component: () => import('@/views/panel/admin/support/BroadcastNotification.vue')
            },
          ]
        }
      ]
    },
    {
      path: '/inject/keybindings',
      name: 'inject-keybindings',
      component: () => import('@/components/user/Keybindings.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('@/views/404.vue')
    }
  ]
});

router.beforeEach(async (to, _, next) => {
  try {
    if (to.meta.ignoreBefore) {
      next();
      return;
    }

    const store = useGeneralStore();

    if (!store.user && (isSessionExisting() || !!to.query.act)) {
      const user = await getMyUser();

      store.setUser(user);

      if (!user) {
        await _logout();

        next({ name: 'login', query: { redirect: btoa(to.fullPath) } });
        return;
      }
    }

    if (to.name?.toString().startsWith('panel-') && !store.user) {
      next({ name: 'login', query: { redirect: btoa(to.fullPath) } });
      return;
    }

    if (to.name === 'login-ingame' || to.name === 'oauth2-authenticate') {
      if (store.user) {
        next();
        return;
      }

      next({ name: 'login', query: { redirect: btoa(to.fullPath) } });
      return;
    }

    if (to.name === 'login') {
      if (store.user) {
        next({name: 'panel-dashboard'});
        return;
      }
    }
  } catch (e) {
    console.error(e);
  }

  if (to.fullPath === '/panel/' || to.fullPath === '/panel') {
    next({ name: 'panel-dashboard' });
    return;
  }

  next();
});

async function _logout() {
  await logout();

  destroySession();
  useGeneralStore().setUser(undefined);
}

export default router
